<template lang="pug">
.container.bg-white.rounded.pt-2
  .row.d-block.d-sm-none.my-3
    .col
      b-button-group.special
        router-link.btn.btn-secondary(:to="{\
          name: 'center-all-consultant-schedule-week',\
        }") Week
        router-link.btn.btn-secondary.active(:to="{\
          name: 'center-all-consultant-schedule-day',\
        }") Day
  .row.d-block.d-sm-none.my-3
    .col.d-flex.justify-content-between.align-items-center
      router-link.btn.btn-secondary(:to="{\
        name: 'center-all-consultant-schedule-day',\
        query: { date: previousTime }\
      }") <
      h2.m-0 {{ toYYYYMMDD(new Date(time)) }}
      router-link.btn.btn-secondary(:to="{\
        name: 'center-all-consultant-schedule-day',\
        query: { date: nextTime }\
      }") >
  .row.d-none.d-sm-block
    .col.my-3
      .d-flex.justify-content-between.align-items-center
        b-button-group
          router-link.btn.btn-secondary(:to="{\
            name: 'center-all-consultant-schedule-day',\
            query: { date: previousTime }\
          }") Previous
          router-link.btn.btn-secondary(:to="{\
            name: 'center-all-consultant-schedule-day',\
            query: { date: nextTime }\
          }") Next
        h2.m-0 {{ toYYYYMMDD(new Date(time)) }}
        b-button-group
            router-link.btn.btn-secondary(:to="{\
              name: 'center-all-consultant-schedule-week',\
            }") Week
            router-link.btn.btn-secondary.active(:to="{\
              name: 'center-all-consultant-schedule-day',\
            }") Day
  .row
    .col
      fixed-table(
        :top-headers="consultants"
        :left-headers="timeSlot"
        :data="data"
        :self-render="false"
      )
        template(#topHeader="{ data: { id, name } }")
          router-link.d-flex.flex-column.align-items-center(
            :to="{\
              name: 'center-consultant-schedule',\
              params: { id },\
            }"
          ) {{ name }}
        template(#leftHeader="{ data }")
          .d-flex.justify-content-center.align-items-center {{ data }}
        template(#body="{data}")
          template(v-if="data")
            b-badge.d-block(:style="{ 'background-color': data.color, color: 'white' }") {{ data.title }}

</template>

<script>
import FullCalendar from '@fullcalendar/vue';

import FixedTable from '@/components/fixedTable/index.vue';

import api from '@lioshutan/api-package';
import storage from '@/utils/localStorage';
import {
  getColorAndTitle,
  timeSlotToTimeRange,
  getNotRestTimeSlotString,
} from '@/utils/schedule';

export default {

  components: {
    FullCalendar,
    FixedTable,
  },

  data() {
    return {
      timeSlot: getNotRestTimeSlotString(),
      consultants: [],
      data: [],
    };
  },

  asyncComputed: {
    async time() {
      const loading = this.$loading.show();
      const date = this.$route.query['date'] ?? this.toYYYYMMDD(new Date());
      const day = new Date(date);

      await this.getDataWithDay(day);

      loading.hide();

      return date;
    },
  },

  computed: {
    nextTime() {
      const nextDay = new Date(this.time);
      nextDay.setDate(nextDay.getDate() + 1);
      return this.toYYYYMMDD(nextDay);
    },
    previousTime() {
      const previousDay = new Date(this.time);
      previousDay.setDate(previousDay.getDate() - 1);
      return this.toYYYYMMDD(previousDay);
    },
  },
  async created() {
  },
  methods: {
    toYYYYMMDD(date) {
      return date.toLocaleDateString('tw', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).replace(/\//g, '-');
    },
    async getDataWithDay(date) {
      const response = (await api
        .setDNS(process.env.VUE_APP_BASE_API)
        .consultant(storage.token)
        .getAllConsultantsScheduleWithDay(date))
        .map(({
          consultantUserId,
          hbConsultantId,
          name,
          sortName,
          timeslots,
        }) => ({
          id: hbConsultantId,
          name: sortName,
          events: Array(31).fill()
            .map((tmp, slotNumber) => {
              const slotData = timeslots
                .find(data => data.timeslot === (slotNumber + 1));
              if (!slotData) {
                return {
                  status: null,
                  type: null,
                  slot: slotNumber + 1,
                  color: null,
                  title: null,
                  start: null,
                  end: null,
                };
              }

              return {
                status: slotData.status,
                type: +slotData.type,
                slot: slotNumber + 1,
                ...getColorAndTitle(slotData.status, +slotData.type),
                ...timeSlotToTimeRange(`${slotData.timestamp}`),
              };
            }).sort((a, b) => {
              if (a.slot === 31) {
                return -1;
              }

              return a.slot - b.slot;
            }).map((data) => data.title ? data : null),
        }));
      console.log(response);
      this.consultants = response.map(({ id, name }) => ({
        id,
        name,
      }));
      this.data = response.map(({ events }) => [
        ...events,
      ]);
    },
  },
};
</script>

<style scoped>
.btn-group.special {
  display: flex;
}

.special .btn {
  flex: 1
}
</style>
