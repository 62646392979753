export const STATUS = {
  LEAVE: 0,
  SHOW: 1,
  PENDING: 2,
  DECLINE: 3,
  CLASS: 4,
  DEMO: 5,
};

export const TYPE = {
  SELF: 0,
  PM: 1,
  CENTER: 2,
  FIXED: 3,
  RESERVED: 4,
};

export const NATIONAL = {
  FOREIGNER: 'foreigner',
  TAIWANESE: 'taiwanese',
};

// 未來陪碩的起始時間可能會不同
export const REST_START = '00:30:00';
export const REST_END = '09:00:00';

/**
 * 將數字轉成時間區間
 *
 * @param {string} slot
 */
export function timeSlotToTimeRange(slot) {
  const dateStr = `${slot[0]}${slot[1]}${slot[2]}${slot[3]}/${slot[4]}${slot[5]}/${slot[6]}${slot[7]}`;
  const slotNum = parseInt(`${slot[8]}${slot[9]}`, 10);

  const start = new Date(`${dateStr} 00:00:00`);
  let end = null;
  if (slotNum === 31) {
    start.setDate(start.getDate() + 1);
    end = new Date(start);
    end.setMinutes(end.getMinutes() + 30);

    return {
      start,
      end,
    };
  }

  const addMinute = (slotNum - 1) * 30;
  start.setHours(9);
  start.setMinutes(0);
  start.setSeconds(0);
  start.setMinutes(start.getMinutes() + addMinute);

  end = new Date(start);
  end.setMinutes(end.getMinutes() + 30);

  return {
    start,
    end,
  };

  // return {
  //   start,
  //   end,
  // };
}

/**
 * 透過時間轉出time slot
 *
 * @param {Date} time
 *
 * @throws 當輸入時間是在休息時間內
 */
export function startTimeToTimeSlotNumber(time) {
  if (isTimeInRestTime(time)) {
    throw Error(`輸入的時間必須介於${REST_START}-${REST_END}`);
  }

  const start = new Date(`${time.toLocaleDateString()} 09:00:00`);
  const totalMilliSeconds = 1000;
  const totalSeconds = 60;
  const step = 30;

  const result = (time - start) / (totalMilliSeconds * totalSeconds * step) + 1;

  return result;
}

/**
 * 取得非休息時間的time slot
 *
 * @param {boolean} includeRestTime 是否包含休息時間
 * @returns
 */
export function getNotRestTimeSlotString(includeRestTime = false) {
  const hours = 24;
  const step = 2;
  const allTimeSlot = Array(hours * step).fill()
    .map((data, index) => parseInt(index / 2, 10))
    .map((data, index) => {
      const now = new Date();
      const minute = index % 2 === 0 ? 0 : 30;
      now.setHours(data);
      now.setMinutes(minute);
      now.setSeconds(0);
      return now;
    })
    .filter(time => includeRestTime || !isTimeInRestTime(time))
    .map(time => time.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
    }));

  return allTimeSlot;
}

/**
 * 檢查該時間段是否在休息時間內
 *
 * @param {Date} start
 * @param {Date} end
 * @returns {boolean} 是否在休息時間內
 */
export function isTimeRangeInRestTime(start, end) {
  return [start, end].reduce(
    (previous, time) => {
      const timeStr = time.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
      return previous && (timeStr <= REST_END) && (timeStr >= REST_START);
    },
    true
  );
}

/**
 * 檢查該時間是否是在休息時間內
 *
 * @param {Date} time
 */
export function isTimeInRestTime(time) {
  const timeStr = time.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

  return (timeStr < REST_END) && (timeStr >= REST_START);
}

/**
 * 檢查時間是否是過去或者是當前時段
 *
 * @param {Date} time 檢測時間
 * @returns {boolean}
 */
export function isPastOrCurrent(time) {
  return getExpTime() > time;
}

/**
 * 取得選課過期時間
 */
function getExpTime() {
  const now = new Date();
  const exp = new Date();

  const isBiggerOrEqualsHalfHour = now.toLocaleTimeString('en-GB', {
    minute: '2-digit',
    second: '2-digit',
  }) > '30:00';

  exp.setSeconds(0);

  if (isBiggerOrEqualsHalfHour) {
    exp.setHours(exp.getHours() + 1);
  } else {
    exp.setMinutes(29);
  }

  return exp;
}

/**
 * 取得起始到結束時間內的所有休息時間
 *
 * @param {Date} start 起始時間
 * @param {Date} end 結束時間
 *
 * @returns {Array}
 */
export function getRestTime(start, end) {
  const days = (end - start) / (1000 * 60 * 60 * 24);
  const formatOption = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  return Array(days).fill().map((day, index) => {
    const startStr = start.toLocaleString('tw', formatOption);
    const startTime = new Date(startStr);
    const endTime = new Date(startStr);

    startTime.setDate(startTime.getDate() + index);
    startTime.setHours(0);
    startTime.setMinutes(30);
    startTime.setSeconds(0);

    endTime.setDate(endTime.getDate() + index);
    endTime.setHours(9);
    endTime.setMinutes(0);
    endTime.setSeconds(0);

    return {
      start: startTime,
      end: endTime,
    };
  });
}

/**
 * 回傳狀態以及類別對應的顏色以及title
 *
 * @param {number} status 狀態
 * @param {number} type 類別
 * @returns {object} title and color
 *
 * @throws 不再內部的規則會返回錯誤
 */
export function getColorAndTitle(status, type) {
  if (status === STATUS.LEAVE) {
    // Leave
    return {
      color: '#777777',
      title: 'Leave',
    };
  }

  if (status === STATUS.PENDING) {
    // Not Approve
    return {
      color: '#2D882D',
      title: 'Pending',
    };
  }

  if (status === STATUS.DECLINE) {
    // Pending
    return {
      color: '#CBB956',
      title: 'Pending',
    };
  }

  if (status === STATUS.CLASS) {
    // Class
    return {
      color: '#3D5A7D',
      title: 'Class',
    };
  }

  if (status === STATUS.DEMO) {
    // Demo
    return {
      color: '#F86C01',
      title: 'Demo',
    };
  }

  if (status === STATUS.SHOW) {
    if (type === TYPE.SELF) {
      // Available
      return {
        color: '#2D882D',
        title: 'Available',
      };
    }

    if (type === TYPE.PM) {
      return {
        color: '#3C01F8',
        title: 'PM Arranged',
      };
    }

    if (type === TYPE.FIXED) {
      return {
        color: '#3C01F8',
        title: 'Fixed Schedule',
      };
    }

    if (type === TYPE.CENTER) {
      // Center Arranged
      return {
        color: '#8785E0',
        title: 'Center Arranged',
      };
    }

    if (type === TYPE.RESERVED) {
      // PM Reserve 4
      return {
        color: '#FFFFFF',
        title: 'PM Reserve',
      };
    }
  }

  throw new Error(`參數錯誤，請確認Status: ${status} & Type: ${type}，是否在規則內！`);
}

export function click(identity, current = { type: 0, status: 0 }) {
  const process = {
    pm: test,
    center: test,
    employee: test,
    student: test,
    consultant: test,
  }[identity];

  process(current);
}

function test(current = { type: 0, status: 0 }) {
  console.log(current);
}

export const CHARACTER = {
  PM: 'pm',
  CENTER: 'center',
  EMPLOYEE: 'employee',
  STUDENT: 'student',
  CONSULTANT: 'consultant',
};
