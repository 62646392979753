<template lang="pug">
.fixed-table-div
  table(v-if="data")
    thead
      tr
        th
        th(v-for="(header, index) in topHeaders" :key="index")
          slot(name="topHeader" :data="header")
    tbody
      tr(v-for="(header, leftHeaderIndex) in leftHeaders" :key="leftHeaderIndex")
        td
          slot(name="leftHeader" :data="header")
        td(v-for="(row, rowIndex) in rowLength" :key="rowIndex")
          template(v-if="selfRender")
            slot(name="body" :colIndex="rowIndex" :rowIndex="leftHeaderIndex")
          template(v-else)
            //- | {{ data[1][leftHeaderIndex] }}
            //- h1 {{ rowIndex }}, {{ leftHeaderIndex }} {{ data }}
            slot(name="body" :data="data[rowIndex][leftHeaderIndex]")
</template>

<script>
export default {
  props: {
    selfRender: {
      type: Boolean,
      default: true,
    },
    topHeaders: {
      type: Array,
      default: () => ([]),
    },
    leftHeaders: {
      type: Array,
      default: () => ([]),
    },
    data: {
      type: Array,
      default: () => (null),
    },
  },
  computed: {
    rowLength() {
      return Array(this.topHeaders.length).fill();
    },
  },
  watch: {
    data() {
      console.log('data....');
    },
  },
  mounted() {
  },

  methods: {
  },

};
</script>

<style scoped>
.fixed-table-div {
  overflow: auto;
  width: 100%;
  height: 600px; /* 固定高度 */

  border: 1px solid black;
  border-collapse: collapse;
}

/* table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
} */

td, th {
  border: 1px solid gray;
  width: 150px;
  height: 30px;
}

th {
  background-color: lightblue;
}

table {
  table-layout: fixed;
  width: 200px; /* 固定寬度 */
}

td:first-child, th:first-child {
  position: sticky;
  left: 0; /* 首行永遠固定於左 */
  z-index: 1;
  background-color: lightpink;
}

thead tr th {
  position: sticky;
  top: 0; /* 列首永遠固定於上 */
}

th:first-child {
  z-index:2;
  background-color:lightblue;
}
</style>
